import { FC } from 'react';
import IconProps from './IconProps';

const TagIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_172_74648)">
        <path
          d="M17.63 5.84C17.27 5.33 16.67 5 16 5L5 5.01C3.9 5.01 3 5.9 3 7V17C3 18.1 3.9 18.99 5 18.99L16 19C16.67 19 17.27 18.67 17.63 18.16L22 12L17.63 5.84ZM16 17H5V7H16L19.55 12L16 17Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_172_74648">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TagIcon;
